<template>
	<b-modal no-close-on-esc no-close-on-backdrop :id="id" hide-header-close>
		<template #modal-title>
			<h4 v-if="passengers.length < 2">Notify "{{ passengers[0].passenger_name }}" ( In-App notifications)</h4>
			<h4 v-else>Notify "{{ passengers[0].passenger_name }}" and {{ passengers.length-1 }} others ( In-App notifications)</h4>
		</template>

		<form @submit.prevent="notifyUsers">
			<div class="form-group">
				<label style="color: #444854" class="form-label">Message Title</label>
				<input
					v-model.trim="form.title"
					required
					type="text"
					class="form-control"
					/>
			</div>
			<div class="form-group">
				<label
					style="color: #444854"
					for="message-description"
					class="col-form-label"
					>Message Description</label
					>
				<textarea
					v-model.trim="form.message"
					rows="4"
					cols="50"
					class="form-control"
					id="message-description"
					placeholder=""
					required
					></textarea>
			</div>

			<!-- <p class="font-width-normal mb-0">SMS Notification</p>
			<p class="text-muted font-weight-light mt-0">
				All users will receive SMS notifications
			</p> -->
			<div class="row align-items-center">
				<div class="col-auto">
					<div class="form-group flex flex-col gap-[10px]">
						<div class="form-check">
							<input
								v-model="form.isSms"
								class="form-check-input"
								type="checkbox"
								id="is-sms-notification"
								/>
							<label class="form-check-label" for="is-sms-notification"
								>SMS Notifications</label
								>
						</div>
						<div class="form-check">
							<input
								v-model="form.isEmail"
								class="form-check-input"
								type="checkbox"
								id="is-email-notification"
								/>
							<label class="form-check-label" for="is-email-notification"
								>Email Notifications</label
								>
						</div>
					</div>
				</div>
			</div>
		</form>

		<template #modal-footer>
			<div class="w-100">
				<b-button
					:disabled="processing || !formValid"
					variant="primary"
					class="float-right"
					@click="notifyUsers"
					>
					{{ processing ? 'Processing...' : 'Proceed' }}
				</b-button>
				<b-button
					:disabled="processing"
					variant="secondary"
					class="float-right mr-2"
					@click="close"
					>
					Close
				</b-button>
			</div>
		</template>
	</b-modal>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      required: true
    },
    // busstop: {
    //   type: String,
    //   required: true
    // },
    passengers: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      form: {
        title: '',
        message: '',
        isSms: false,
		isEmail: false
      },
      processing: false
    }
  },
  computed: {
    formValid () {
      return !!(this.form.title.length && this.form.message.length)
    }
  },
  methods: {
    close () {
      this.$emit('close')
      this.form.title = ''
      this.form.message = ''
      this.form.isSms = false
	  this.form.isEmail = false
    },
    notifyUsers () {
      if (!this.formValid) {
        return
      }
      this.processing = true

      const payload = {
        user_ids: this.passengers.map((passenger) => passenger.user.id),
        title: this.form.title,
        body: this.form.message,
        sms: this.form.isSms,
		email: this.form.isEmail
      }

      this.axios
        .post('/v1/notifications', {
          ...payload
        })
        .then(() => {
          this.form.title = ''
          this.form.message = ''
          this.form.isSms = false
		  this.form.isEmail = false
          this.close()

          this.$emit('successfullyNotified')
        })
        .catch((e) => {
          let msg = e.toString()
          if (e && e.response && e.response.data && e.response.data.message) {
            msg = e.response.data.message
          }

          this.$toastr.e(msg, 'Error')
        })
        .finally(() => (this.processing = false))
    }
  }
}
</script>

<style scoped>
.container {
  display: flex;
  align-items: center;
}

textarea {
  resize: none;
}
</style>
